import debounce from "lodash/debounce"
import generateShortID from "../../../../../utils"
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS"
import { FEATURES_LIST_ENUM } from "../../../components/settings/general/constants"
import { DATA_TYPES } from "./components/constants"

export const UPDATE_TEST_DEVICES = "UPDATE_TEST_DEVICES"
export const SET_GOAL_MET_CHECK = "SET_GOAL_MET_CHECK"
export const REMOVE_TEST_DEVICE = "REMOVE_TEST_DEVICE"
export const SET_CONDITIONS_TYPE = "SET_CONDITIONS_TYPE"
export const SET_ENABLE_CONDITIONS = "SET_ENABLE_CONDITIONS"
export const SET_ENABLE_CONDITIONS_V2 = "SET_ENABLE_CONDITIONS_V2"
export const ENABLE_CONDITIONS = "ENABLE_CONDITIONS"
export const SET_ADD_CONDITION = "SET_ADD_CONDITION"
export const SET_DEMO_UPSERT_CONDITION = "SET_DEMO_UPSERT_CONDITION"
export const SET_MOVE_CONDITION = "SET_MOVE_CONDITION"
export const SET_REMOVE_CONDITION = "SET_REMOVE_CONDITION"
export const SET_UPDATE_CONDITION = "SET_UPDATE_CONDITION"
export const SET_APP_SCREENS = "SET_APP_SCREENS"
export const SET_APP_SCREEN_NAMES = "SET_APP_SCREEN_NAMES"
export const SET_AUDIENCE_TYPE = "SET_AUDIENCE_TYPE"
export const SET_AUDIENCE_USER_ATTR = "SET_AUDIENCE_USER_ATTR"
export const SET_AUTO_DISMISS_DURATION = "SET_AUTO_DISMISS_DURATION"
export const SET_AUTO_DISMISS_ENABLED = "SET_AUTO_DISMISS_ENABLED"
export const SET_COHORT_ID = "SET_COHORT_ID"
export const SET_DYNAMIC_COHORT_ID = "SET_DYNAMIC_COHORT_ID"
export const SET_CONFIG = "SET_CONFIG"
export const SET_SURVEY_TYPE = "SET_SURVEY_TYPE"
export const SET_CONTROL_GROUP = "SET_CONTROL_GROUP"
export const SET_NO_CONTEXT_ENABLED = "SET_NO_CONTEXT_ENABLED"
export const SET_CONTEXT_TYPE = "SET_CONTEXT_TYPE"
export const SET_DIDNOT_DO_EVENTS = "SET_DIDNOT_DO_EVENTS"
export const SET_EVENTS = "SET_EVENTS"
export const SET_FREQ_COUNT = "SET_FREQ_COUNT"
export const SET_FREQ_DAYS_LIMIT = "SET_FREQ_DAYS_LIMIT"
export const SET_FREQ_DAYS = "SET_FREQ_DAYS"
export const SET_RECUR_TYPE = "SET_RECUR_TYPE"
export const SET_REPETITION_GAP_ENABLED = "SET_REPETITION_GAP_ENABLED"
export const SET_REPETITION_GAP_VALUE = "SET_REPETITION_GAP_VALUE"
export const SET_REPETITION_GAP_UNIT = "SET_REPETITION_GAP_UNIT"
export const SET_FREQ_LIMITS_ENABLED = "SET_FREQ_LIMITS_ENABLED"
export const SET_FREQ_DAY_LIMIT = "SET_FREQ_DAY_LIMIT"
export const SET_DAYS_LIMITS_ENABLED = "SET_DAYS_LIMITS_ENABLED"
export const SET_COUNT_LIMIT_ENABLED = "SET_COUNT_LIMIT_ENABLED"
export const SET_FREQ_SESSION_LIMIT = "SET_FREQ_SESSION_LIMIT"
export const SET_FREQ_VALIDITY = "SET_FREQ_VALIDITY"
export const SET_GOAL_EVENT = "SET_GOAL_EVENT"

export const SET_PRIMARY_GOAL_EVENT_TYPE = "SET_PRIMARY_GOAL_EVENT_TYPE"
export const SET_PRIMARY_GOAL_EVENT = "SET_PRIMARY_GOAL_EVENT"
export const SET_PRIMARY_GOAL_EVENT_OPERATOR = "SET_PRIMARY_GOAL_EVENT_OPERATOR"
export const SET_PRIMARY_GOAL_EVENT_VALUE = "SET_PRIMARY_GOAL_EVENT_VALUE"
export const SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_ELEMENT =
  "SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_ELEMENT"
export const SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE =
  "SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE"
export const SET_PRIMARY_GOAL_EVENT_REMOVE_ATTRIBUTE_VALUES =
  "SET_PRIMARY_GOAL_EVENT_REMOVE_ATTRIBUTE_VALUES"
export const SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR =
  "SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR"
export const SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES =
  "SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES"

export const SET_META_CONFIG_TYPE = "SET_META_CONFIG_TYPE"
export const SET_META_TYPE = "SET_META_TYPE"
export const SET_META_NAME = "SET_META_NAME"
export const SET_META_DESC = "SET_META_DESC"
export const SET_META_PLATFORM = "SET_META_PLATFORM"
export const SET_META_IS_MWEB = "SET_META_IS_MWEB"
export const SET_META_USECASE = "SET_META_USECASE"
export const SET_META_TAGS = "SET_META_TAGS"
export const SET_NTH_EVENT = "SET_NTH_EVENT"
export const SET_OPERATOR_AND_SEQUENCE_ENABLED =
  "SET_OPERATOR_AND_SEQUENCE_ENABLED"
export const SET_PRIORITY = "SET_PRIORITY"
export const SET_RETAINED_DAY_FROM = "SET_RETAINED_DAY_FROM"
export const SET_RETAINED_DAY_TO = "SET_RETAINED_DAY_TO"
export const SET_SESSION_LIMIT_FROM = "SET_SESSION_LIMIT_FROM"
export const SET_SESSION_LIMIT_TO = "SET_SESSION_LIMIT_TO"
export const SET_SEGMENT = "SET_SEGMENT"
export const SET_ROLL_INTERVAL = "SET_ROLL_INTERVAL"
export const SET_ROLL_RECURR = "SET_ROLL_RECURR"
export const SET_ROLL_STEP = "SET_ROLL_STEP"
export const SET_TEST_DEVICES = "SET_TEST_DEVICES"
export const SET_ENABLE_TESTING = "SET_ENABLE_TESTING"
export const SET_TIME_LIMITS_END = "SET_TIME_LIMITS_END"
export const SET_TIME_LIMITS_START = "SET_TIME_LIMITS_START"
export const SET_TIME_SPENT = "SET_TIME_SPENT"
export const SET_TOGGLE_RECURR = "SET_TOGGLE_RECURR"
export const SET_TOGGLE_RETAINED_DAY = "SET_TOGGLE_RETAINED_DAY"
export const SET_TOGGLE_ROLL = "SET_TOGGLE_ROLL"
export const SET_TOGGLE_SESSION_LIMITS = "SET_TOGGLE_SESSION_LIMITS"
export const SET_TOGGLE_TIME_LIMITS_ENABLED = "SET_TOGGLE_TIME_LIMITS_ENABLED"
export const SET_TOGGLE_EXCLUDE_TIME_LIMITS_ENABLED =
  "SET_TOGGLE_EXCLUDE_TIME_LIMITS_ENABLED"
export const SET_TOGGLE_USER_TIME_ZONE_ENABLED =
  "SET_TOGGLE_USER_TIME_ZONE_ENABLED"
export const SET_UI = "SET_UI"
export const SET_UIS = "SET_UIS"
export const SET_VALIDITY_END = "SET_VALIDITY_END"
export const SET_PUBLISH_TIME_ENABLED = "SET_PUBLISH_TIME_ENABLED"
export const SET_UNTIL_CAMPAIGN_PAUSED_ENABLED =
  "SET_UNTIL_CAMPAIGN_PAUSED_ENABLED"
export const SET_IS_DAYS_IN_WEEK_ENABLED = "SET_IS_DAYS_IN_WEEK_ENABLED"
export const SET_VALIDITY_START = "SET_VALIDITY_START"
export const SET_DAYS_IN_WEEK = "SET_DAYS_IN_WEEK"
export const SET_EXCLUDE_TIME_LIMITS_START = "SET_EXCLUDE_TIME_LIMITS_START"
export const SET_EXCLUDE_TIME_LIMITS_END = "SET_EXCLUDE_TIME_LIMITS_END"
export const SET_EXCLUDE_TIME_LIMITS_ENABLE = "SET_EXCLUDE_TIME_LIMITS_ENABLE"
export const SET_IS_USER_TIMEZONE = "SET_IS_USER_TIMEZONE"
export const SET_DYNAMIC_COHORTS = "SET_DYNAMIC_COHORTS"
export const SET_COHORTS = "SET_COHORTS"
export const SET_SEGMENTS = "SET_SEGMENTS"
export const SET_DEFAULT_TEST_DEVICES = "SET_DEFAULT_TEST_DEVICES"
export const SET_CLIENT_EVENTS = "SET_CLIENT_EVENTS"
export const SET_SCHEDULING_INSTANCE = "SET_SCHEDULING_INSTANCE"
export const SET_SELECTED_TEST_DEVICE = "SET_SELECTED_TEST_DEVICE"
export const SET_SCHEDULING_TIME = "SET_SCHEDULING_TIME"
export const SET_SURVEY_DELAY = "SET_SURVEY_DELAY"

export const ADD_NEW_PROPERTY_IN_AUDIENCE = "ADD_NEW_PROPERTY_IN_AUDIENCE"
export const SET_AUDIENCE_ATTRIBUTE = "SET_AUDIENCE_ATTRIBUTE"
export const SET_AUDIENCE_ATTRIBUTES_ARRAY = "SET_AUDIENCE_ATTRIBUTES_ARRAY"
export const REMOVE_AUDIENCE_ATTRIBUTE = "REMOVE_AUDIENCE_ATTRIBUTE"
export const SET_TOGGLE_USER_ATTRIBUTES = "SET_TOGGLE_USER_ATTRIBUTES"
export const SET_TOGGLE_SESSION_ATTRIBUTES = "SET_TOGGLE_SESSION_ATTRIBUTES"
export const SET_TOGGLE_APP_VERSION_FILTER = "SET_TOGGLE_APP_VERSION_FILTER"
export const SET_VERSION_OPERATOR = "SET_VERSION_OPERATOR"
export const SET_VERSION_VALUE = "SET_VERSION_VALUE"
export const SET_EXCLUDE_ANONYMOUS_USERS = "SET_EXCLUDE_ANONYMOUS_USERS"
export const SET_TARGET_ANONYMOUS_USERS_ONLY = "SET_TARGET_ANONYMOUS_USERS_ONLY"
export const SET_EXPERIMENT = "SET_EXPERIMENT"
export const SET_EXCLUDE_COHORT_TOGGLE = "SET_EXCLUDE_COHORT_TOGGLE"
export const SET_EXCLUDE_EVENTS_TOGGLE = "SET_EXCLUDE_EVENTS_TOGGLE"
export const SET_EXCLUDE_COHORT_ID = "SET_EXCLUDE_COHORT_ID"

export const SET_SNACKBAR_ERROR_MESSAGE = "SET_SNACKBAR_ERROR_MESSAGE"

export const SET_VARIANT_PERCENTAGE = "SET_VARIANT_PERCENTAGE"
export const SET_TARGET_PERCENTAGE = "SET_TARGET_PERCENTAGE"
export const SET_AUTO_PUBLISH_WINNER = "SET_AUTO_PUBLISH_WINNER"
export const ADD_STEP_TO_VARIANT = "ADD_STEP_TO_VARIANT"
export const ADD_VARIANT = "ADD_VARIANT"
export const SET_CURRENT_VARIANT_STEP = "SET_CURRENT_VARIANT_STEP"
export const DELETE_VARIANT = "DELETE_VARIANT"
export const COPY_VARIANT_STEP = "ON_COPY_STEP"

export const SET_SELECTED_TEST_VARIANT = "SET_SELECTED_TEST_VARIANT"
export const DELETE_STEP_FROM_VARIANT = "DELETE_STEP_FROM_VARIANT"
export const ON_COPY_STEP_VARIANT = "ON_COPY_STEP_VARIANT"

export const SET_TEST_START_DATE = "SET_TEST_START_DATE"
export const SET_TEST_END_DATE = "SET_TEST_END_DATE"
export const SET_TEST_SHOW_AT_SPECIFIC_TIME = "SET_TEST_SHOW_AT_SPECIFIC_TIME"
export const SET_TEST_TIME_LIMITS_START = "SET_TEST_TIME_LIMITS_START"
export const SET_TEST_TIME_LIMITS_END = "SET_TEST_TIME_LIMITS_END"

export const SET_PUBLISH_START_DATE = "SET_PUBLISH_START_DATE"
export const SET_PUBLISH_END_DATE = "SET_PUBLISH_END_DATE"
export const SET_PUBLISH_SHOW_AT_SPECIFIC_TIME =
  "SET_PUBLISH_SHOW_AT_SPECIFIC_TIME"
export const SET_PUBLISH_TIME_LIMITS_START = "SET_PUBLISH_TIME_LIMITS_START"
export const SET_PUBLISH_TIME_LIMITS_END = "SET_PUBLISH_TIME_LIMITS_END"
export const SET_CAMPAIGN_PUBLISH_CG = "SET_CAMPAIGN_PUBLISH_CG"

export const ADD_CUSTOM_FONT = "ADD_CUSTOM_FONT"
export const REMOVE_CUSTOM_FONT = "REMOVE_CUSTOM_FONT"

export const SET_GLOBAL_THEME = "SET_GLOBAL_THEME"
export const REMOVE_GLOBAL_THEME = "REMOVE_GLOBAL_THEME"

// conditon_v2 constants
export const NEW_ADD_EVENT = "NEW_ADD_EVENT"
export const NEW_REMOVE_EVENT = "NEW_REMOVE_EVENT"
export const NEW_ADD_INNER_EVENT = "NEW_ADD_INNER_EVENT"
export const NEW_REMOVE_INNER_EVENT = "NEW_REMOVE_INNER_EVENT"
export const NEW_EVENT_TYPE = "NEW_EVENT_TYPE"
export const NEW_EVENT_OPERATOR = "NEW_EVENT_OPERATOR"
export const NEW_EVENT_COUNT = "NEW_EVENT_COUNT"
export const SET_NEW_EVENT_DETAILS = "SET_NEW_EVENT_DETAILS"
export const SET_NEW_INNER_COMBINE_OPERATOR = "SET_NEW_INNER_COMBINE_OPERATOR"
export const SET_NEW_OUTER_COMBINE_OPERATOR = "SET_NEW_OUTER_COMBINE_OPERATOR"
export const SET_NEW_INNER_COMBINE_OPERATOR_FRONTEND =
  "SET_NEW_INNER_COMBINE_OPERATOR_FRONTEND"
export const SET_NEW_OUTER_COMBINE_OPERATOR_FRONTEND =
  "SET_NEW_OUTER_COMBINE_OPERATOR_FRONTEND"
export const SET_ATTRIBUTES_OPERATOR = "SET_ATTRIBUTES_OPERATOR"
export const ADD_NEW_PROPERTY = "ADD_NEW_PROPERTY"
export const NEW_DELETE_PROPERTY = "NEW_DELETE_PROPERTY"
export const NEW_DELETE_ADDITIONAL_INFO = "NEW_DELETE_ADDITIONAL_INFO"
export const ADD_NEW_DATATYPE = "ADD_NEW_DATATYPE"
export const ADD_NEW_OPERATOR = "ADD_NEW_OPERATOR"
export const ADD_NEW_PROPERTY_VALUE = "ADD_NEW_PROPERTY_VALUE"
export const SET_CASE_SENSITIVE_ENABLED = "SET_CASE_SENSITIVE_ENABLED"
export const SET_EXCLUDE_EVENTS = "SET_EXCLUDE_EVENTS"

export const SET_NEW_ORDER_SEQUENCE = "SET_NEW_ORDER_SEQUENCE"
export const SET_NEW_ENABLE_TIME_OUTER = "SET_NEW_ENABLE_TIME_OUTER"
export const SET_NEW_ENABLE_TIME_INNER = "SET_NEW_ENABLE_TIME_INNER"
export const SET_NEW_TIME_OUTER = "SET_NEW_TIME_OUTER"
export const SET_NEW_TIME_INNER = "SET_NEW_TIME_INNER"
export const SET_NEW_ORDER_SEQUENCE_PARENT = "SET_NEW_ORDER_SEQUENCE_PARENT"
export const SET_NEW_ENABLE_ACROSS_SESSION = "SET_NEW_ENABLE_ACROSS_SESSION"
export const SET_SESSIONS_TYPE = "SET_SESSIONS_TYPE"
export const SET_NEW_NUMBER_OF_ACROSS_SESSION =
  "SET_NEW_NUMBER_OF_ACROSS_SESSION"
export const SET_NEW_EVENT_CATEGORY = "SET_NEW_EVENT_CATEGORY"

export const DEFAULT_NEW_SINGLE_CONDITIONS = {
  _id: "",
  condition_type: "single", // "single" | "group"
  event_type: "", // "app_event" | "client_event" | "activity_event" | "screen_event"
  type: "did", // "did" | "didn't"
  event_category: "specific_event", //"specific_event"|"any_event"|"apxor_event"|"customer_event"|"campaign_event"|"survey_event"|"navigation_event"|"system_event"
  activity: "", // only if `event_type` is "activity_event"
  screen: "", // only if `event_type` is "screen_event"
  details: {
    name: "", // only if `event_type` is "app_event" | "client_event"
    attributes_op: "AND",
    additional_info: {},
  },
  count_config: {
    operator: "GTE", // "GT" | "LT" | "GTE" | "LTE" | "EQ" | "NEQ"
    count: 1, // interger data type
  },
  time_enabled: false, // always `false` for 1st item in the array
  time: {
    unit: "second", // "second" | "minute" | "hour" | "day" | "session",
    value: 10, // float data type
  },
  combine_operator_frontend: "OR", // "OR" | "AND" | "AND_THEN" (this is just a frontend variable to accomodate ANT_THEN functionality)
  combine_operator: "OR", // "OR" | "AND" (can be a sibling of `conditions_v2`, but will be copied to all condition objects)

  // name-sake
  time_bounds: {
    lower: 0,
    upper: 60000000,
  },
  trigger: {
    event_type: "app_start",
    activity: "",
    details: {
      name: "",
      additional_info: {},
    },
    time_bounds: {
      lower: 0,
      upper: 60000000,
    },
  },
}
export const DEFAULT_NEW_GROUP_CONDITIONS = {
  _id: generateShortID(),
  condition_type: "group", // "single" | "group"
  event_type: "", // "app_event" | "client_event" | "activity_event" | "screen_event"
  event_category: "specific_event", //"specific_event"|"any_event"|"apxor_event"|"customer_event"|"campaign_event"|"survey_event"|"navigation_event"|"system_event"
  type: "did", // "did" | "didn't"
  activity: "", // only if `event_type` is "activity_event"
  screen: "", // only if `event_type` is "screen_event"
  details: {
    name: "", // only if `event_type` is "app_event" | "client_event"
    additional_info: {},
  },
  count_config: {
    operator: "GT", // "GT" | "LT" | "GTE" | "LTE" | "EQ" | "NEQ"
    count: 2, // interger data type
  },
  time_enabled: false, // always `false` for 1st item in the array
  time: {
    unit: "second", // "second" | "minute" | "hour" | "day" | "session",
    value: 1.0, // float data type
  },
  combine_operator: "OR", // "OR" | "AND" (can be a sibling of `conditions_v2`, but will be copied to all condition objects)

  // if `conditions_type` is `group`
  sequence_type: "unordered", // "unordered" | "ordered" | "strictly_ordered"
  conditions_v2: [],

  // name-sake
  time_bounds: {
    lower: 0,
    upper: 60000000,
  },
  trigger: {
    event_type: "app_start",
    activity: "",
    details: {
      name: "",
      additional_info: {},
    },
    time_bounds: {
      lower: 0,
      upper: 60000000,
    },
  },
}

//export const GENERAL = "GENERAL";
export const DESIGN = "DESIGN"
export const CONTEXT = "CONTEXT"
export const STRATEGY = "STRATEGY"
export const SUMMARY = "SUMMARY"
export const TARGET = "TARGET"
export const SCHEDULE = "SCHEDULE"
export const REVIEW = "REVIEW"

export const DEFAULT_STEP_ORDER = {
  // GENERAL: 0,
  DESIGN: 0,
  TARGET: 1,
  SCHEDULE: 2,
  REVIEW: 3,
}
export const DEFAULT_EVENT_CATEGORY = [
  { value: "specific_event", label: "specific_event" },
  { value: "any_event", label: "any_event" },
  { value: "apxor_event", label: "apxor_event" },
  { value: "customer_event", label: "customer_event" },
  { value: "campaign_event", label: "campaign_event" },
  { value: "survey_event", label: "survey_event" },
  { value: "navigation_event", label: "navigation_event" },
  { value: "system_event", label: "system_event" },
]
export const MESSAGE_CONFIGURATIONS = {
  //GENERAL: "General",
  DESIGN: "Design",
  TARGET: "Target",
  SCHEDULE: "Schedule",
  REVIEW: "Review",
}

export const APP_PLATFORMS = {
  android: "android",
  ios: "ios",
  web: "web",
  omni: "omni",
}

export const CONDTIONAL_OPERATORS = {
  AND: "AND",
  OR: "OR",
}

export const EVENT_CONDTION_TYPES = {
  DEFAULT_CS: {
    cs: "conditions",
    se: "sequence_enabled",
  },
  DOWNLOAD_CS: {
    cs: "pre_cs",
    se: "pre_cs_seq_enabled",
  },
  DELETE_CS: {
    cs: "terminate_info.conditions",
    se: "terminate_info.sequence_enabled",
  },
}

export const TRIGGER_CONFIG = {
  trigger: {
    // DEFAULT FOR ALL (app_start)
    event_type: "app_start",
    activity: "",
    details: {
      name: "",
      additional_info: {},
    },
    time_bounds: {
      lower: 0,
      upper: 60000000,
    },
  },
}

export const DEFAULT_GOAL_EVENT_STATE = {
  primary_goal: {
    type: "event", //| "session" | "event",
    name: "",
    operator: "EQ",
    value: [],

    // only consider if type is event
    attributes: [],
  },
}

export const TIME_SPENT_TRIGGER = {
  trigger: {
    ...TRIGGER_CONFIG.trigger,
    event_type: "activity_event",
  },
}

export const NTH_EVENT_DEFAULT_CONFIG = {
  event_type: "app_event",
  dummy_event_type: "nth",
  time_bounds: {
    lower: 0,
    upper: 6000000,
  },
  count_config: {
    count: 1,
    operator: "GTE",
  },
  combine_operator: CONDTIONAL_OPERATORS.OR,
  activity: "",
  details: {
    additional_info: {},
    name: "",
  },
  ...TRIGGER_CONFIG,
}

export const NO_KPI_DEFAULT_CONFIG = {
  event_type: "idle_time",
  time_bounds: {
    lower: 0,
    upper: 6000000,
  },
  count_config: {
    count: 1,
    operator: "GTE",
  },
  combine_operator: CONDTIONAL_OPERATORS.OR,
  activity: "",
  details: {
    additional_info: {
      time: 5000,
      nkpi: [],
    },
    name: "",
  },
  ...TIME_SPENT_TRIGGER,
}

export const TARGET_AUDIENCE_ENUM = Object.freeze({
  ALL: {
    key: "ALL",
    title: "All Users",
  },
  SEGMENT: {
    key: "SEGMENT",
    title: "Segment",
    flag: FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.SEGMENTS.value,
  },
  COHORT: {
    key: "COHORT",
    title: "Cohort",
    flag: FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.COHORTS.value,
  },
  DYNAMIC_COHORT: {
    key: "DYNAMIC_COHORT",
    title: "Dynamic Cohort",
    flag: FEATURES_LIST_ENUM.TARGET_AUDIENCE.children.DYNAMIC_COHORTS.value,
  },
  NEW_USERS: {
    key: "NEW_USERS",
    title: "New Users",
  },
})

export const GOAL_EVENT_ENUM = Object.freeze({
  EVENT: {
    key: "event",
    title: "Event",
  },
  USER: {
    key: "user",
    title: "User Properties",
  },
  SESSION: {
    key: "session",
    title: "Session Properties",
  },
})

export const AUDIENCE_TYPES = [
  {
    title: "All Users",
    key: "ALL",
    disabled: false,
  },
  {
    title: "Segment",
    key: "SEGMENT",
    disabled: false,
  },
  {
    title: "Cohort",
    key: "COHORT",
    disabled: false,
  },
  {
    title: "New Users",
    key: "NEW_USERS",
    disabled: false,
  },
  {
    title: "Dynamic Cohort",
    key: "DYNAMIC_COHORT",
    disabled: false,
  },
]

export const getAudienceIndex = (audienceType) => {
  return AUDIENCE_TYPES.map((x) => x.key).indexOf(audienceType)
}

export const CLIENT_EVENTS_FOR_APP = {
  d831634e11cf6c424b817e22761710e2: {
    "Bottom Nav Selection": {
      selection: "string",
    },
    CommentAdded: {
      apx_lt_count: "number",
    },
    CommentListLoaded: {
      apx_lt_count: "number",
      appVersion: "string",
    },
    "Create Team Loaded": {
      apx_lt_count: "number",
      appVersion: "string",
      source: "string",
    },
    "Match Center Loaded": {
      appVersion: "string",
    },
    matchcentredloaded: {
      appVersion: "string",
    },
    CommentLongPressTapped: {
      appVersion: "string",
    },
    FeedCampaignButtonClicked: {
      sportName: "string",
    },
    FeedLoadedClient: {},
    "Round Selected": {
      appVersion: "string",
      source: "string",
      roundStatus: "string",
    },
    ContestPageInitiated: {
      appVersion: "string",
    },
    ContestShown: {
      appVersion: "string",
    },
    ScreenLoaded: {
      appVersion: "string",
      TabSelected: "string",
      ScreenName: "string",
      sportName: "string",
    },
    PlayerStatsOpen: {
      appVersion: "string",
      onboardingFlag: "boolean",
      sportName: "string",
      roundId: "number",
      contestId: "string",
      pipRoundId: "number",
      source: "string",
      ActiveScreenName: "string",
    },
    "League Card Clicked": {
      appVersion: "string",
      sportName: "string",
      contestType: "string",
      category: "string",
      isConfirmed: "boolean",
      contestCategory: "string",
      ActiveScreenName: "string",
    },
    "Create Team Next Tapped": {
      appVersion: "string",
      onboardingFlag: "boolean",
      sportName: "string",
      tourId: "number",
      pipRoundId: "number",
      ActiveScreenName: "string",
      abTestParameter: "string",
      roundId: "number",
    },
  },
}

export const CAMPAIGN_TYPE_ENUM = {
  IN_APP: "IN_APP",
  IN_LINE: "IN_LINE",
  WALKTHROUGH: "WALKTHROUGH",
  ONBOARDING: "ONBOARDING",
}

export const IOS_DISPLAY_TYPE_ENUM = Object.freeze({
  COACHMARK: "coach_mark",
  INLINE: "inline",
  INAPP: "inapp",
  PASSIVE_NUDGE: "passive_nudge",
})

export const DEFAULT_DATA_TYPES = [
  {
    label: DATA_TYPES.STRING,
    value: "s",
  },
  {
    label: DATA_TYPES.BOOLEAN,
    value: "b",
  },
  {
    label: DATA_TYPES.DECIMAL,
    value: "f",
  },
  {
    label: DATA_TYPES.INTEGER,
    value: "l",
  },
]

export const CONTEXT_EVENT_TYPES_ENUM = {
  ACTIVITY: "activity_event",
  APP: "app_event",
  MESSAGE: "app_event",
  CLIENT: "client_event",
}

export const CONDITIONS_ENUM = {
  AND: "AND",
  OR: "OR",
}

export const MESSAGE_ENUM = {
  IN_APP: "IN_APP",
  IN_LINE: "IN_LINE",
  WALKTHROUGH: "WALKTHROUGH",
}

export const SCHEDULING_ENUM = {
  NOW: "NOW",
  LATER: "LATER",
}

export const TOGGLE_SWITCH_STYLES = (disabled = false) => ({
  trackBgColor: disabled ? "#4697028a" : "#469702",
  trackBorderColor: disabled ? "#4697028a" : "#469702",
  trackInActiveBgColor: "#677A87",
})

export const executeWithDebounce = (delay = 700) =>
  debounce((fn, ...args) => {
    fn(...args)
  }, delay)

export const DISPLAY_TEXT_ENUM = {
  AddClicked: "click on Add button of an item",
  BuyNowClicked: "click on BuyNow",
  HomeMenuClicked: "click on Menu in the Hamburger",
  HomePageLaunched: "land on HomePage",
  ItemDeleted: "delete an item from the cart",
  ItemSelected: "click on an item",
  LoginClicked: "click on login",
  LoginPageLaunched: "land on LoginPage",
  LogOutClicked: "click on logout",
  MyCartClicked: "click on the CartIcon",
  MyCartPageLaunched: "land on CartPage",
  OrderDetailPageLaunched: "land on OrderDetailsPage",
  OrderHistoryClicked: "click on OrderHistory",
  OTPEntered: "enter the OTP",
  PlaceOrderClicked: "click on PlaceOrder",
  ProfileClicked: "click on Profile",
  QuantityDecreased: "decrease quantity of an item in the cart",
  QuantityIncreased: "increase quantity of an item in the cart",
  SearchClicked: "click on search icon",
  SearchDismissClicked: "click on X in the search bar",
  SearchIconClicked: "click on the search icon",
  SearchResultsPageLaunched: "land on the search results page",
  ShopByCategoryClicked: "click on ShopByCategory menu item",
  ShopByCategorySelected: "select a category to shop",
  OkClicked: "confirm the order placed",
}

export const ACCEPTED_FONT_FILE_FORMATS = [".ttf", ".otf", ".TTF", ".OTF"]

export const ANDROID_DEFAULT_FONTS = [
  {
    label: "Serif",
    value: "serif",
  },
  {
    label: "Sans Serif",
    value: "sans-serif",
  },
  {
    label: "Monospace",
    value: "monospace",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
]

export const WEB_DEFAULT_FONTS = [
  {
    label: "Inherit",
    value: "inherit",
  },
  {
    label: "Sans Serif",
    value: "sans-serif",
  },
  {
    label: "Serif",
    value: "serif",
  },
  {
    label: "Monospace",
    value: "monospace",
  },
]

export const IOS_DEFAULT_FONTS = [
  {
    label: "Academy Engraved LET",
    value: "AcademyEngravedLetPlain",
  },
  {
    label: "Al Nile",
    value: "AlNile",
  },
  {
    label: "Al Nile Bold",
    value: "AlNile-Bold",
  },
  {
    label: "American Typewriter",
    value: "AmericanTypewriter",
  },
  {
    label: "American Typewriter Light",
    value: "AmericanTypewriter-Light",
  },
  {
    label: "American Typewriter Semibold",
    value: "AmericanTypewriter-Semibold",
  },
  {
    label: "American Typewriter Bold",
    value: "AmericanTypewriter-Bold",
  },
  {
    label: "American Typewriter Condensed",
    value: "AmericanTypewriter-Condensed",
  },
  {
    label: "American Typewriter Condensed Light",
    value: "AmericanTypewriter-CondensedLight",
  },
  {
    label: "American Typewriter Condensed Bold",
    value: "AmericanTypewriter-CondensedBold",
  },
  {
    label: "Apple Color Emoji",
    value: "AppleColorEmoji",
  },
  {
    label: "Apple SD Gothic Neo Regular",
    value: "AppleSDGothicNeo-Regular",
  },
  {
    label: "Apple SD Gothic Neo Thin",
    value: "AppleSDGothicNeo-Thin",
  },
  {
    label: "Apple SD Gothic Neo UltraLight",
    value: "AppleSDGothicNeo-UltraLight",
  },
  {
    label: "Apple SD Gothic Neo Light",
    value: "AppleSDGothicNeo-Light",
  },
  {
    label: "Apple SD Gothic Neo Medium",
    value: "AppleSDGothicNeo-Medium",
  },
  {
    label: "Apple SD Gothic Neo SemiBold",
    value: "AppleSDGothicNeo-SemiBold",
  },
  {
    label: "Apple SD Gothic Neo Bold",
    value: "AppleSDGothicNeo-Bold",
  },
  {
    label: "Apple Symbols",
    value: "AppleSymbols",
  },
  {
    label: "Arial",
    value: "Arial",
  },
  {
    label: "Arial Italic",
    value: "Arial-ItalicMT",
  },
  {
    label: "Arial Bold",
    value: "Arial-BoldMT",
  },
  {
    label: "Arial Bold Italic",
    value: "Arial-BoldItalicMT",
  },
  {
    label: "Arial Hebrew",
    value: "ArialHebrew",
  },
  {
    label: "Arial Hebrew Light",
    value: "ArialHebrew-Light",
  },
  {
    label: "Arial Hebrew Bold",
    value: "ArialHebrew-Bold",
  },
  {
    label: "Arial Rounded MT Bold",
    value: "ArialRoundedMTBold",
  },
  {
    label: "Avenir Book",
    value: "Avenir-Book",
  },
  {
    label: "Avenir Roman",
    value: "Avenir-Roman",
  },
  {
    label: "Avenir Book Oblique",
    value: "Avenir-BookOblique",
  },
  {
    label: "Avenir Oblique",
    value: "Avenir-Oblique",
  },
  {
    label: "Avenir Light",
    value: "Avenir-Light",
  },
  {
    label: "Avenir Light Oblique",
    value: "Avenir-LightOblique",
  },
  {
    label: "Avenir Medium",
    value: "Avenir-Medium",
  },
  {
    label: "Avenir Medium Oblique",
    value: "Avenir-MediumOblique",
  },
  {
    label: "Avenir Heavy",
    value: "Avenir-Heavy",
  },
  {
    label: "Avenir Heavy Oblique",
    value: "Avenir-HeavyOblique",
  },
  {
    label: "Avenir Black",
    value: "Avenir-Black",
  },
  {
    label: "Avenir Black Oblique",
    value: "Avenir-BlackOblique",
  },
  {
    label: "Avenir Next Regular",
    value: "AvenirNext-Regular",
  },
  {
    label: "Avenir Next Italic",
    value: "AvenirNext-Italic",
  },
  {
    label: "Avenir Next UltraLight",
    value: "AvenirNext-UltraLight",
  },
  {
    label: "Avenir Next UltraLight Italic",
    value: "AvenirNext-UltraLightItalic",
  },
  {
    label: "Avenir Next Medium",
    value: "AvenirNext-Medium",
  },
  {
    label: "Avenir Next Medium Italic",
    value: "AvenirNext-MediumItalic",
  },
  {
    label: "Avenir Next DemiBold",
    value: "AvenirNext-DemiBold",
  },
  {
    label: "Avenir Next DemiBold Italic",
    value: "AvenirNext-DemiBoldItalic",
  },
  {
    label: "Avenir Next Bold",
    value: "AvenirNext-Bold",
  },
  {
    label: "Avenir Next Bold Italic",
    value: "AvenirNext-BoldItalic",
  },
  {
    label: "Avenir Next Heavy",
    value: "AvenirNext-Heavy",
  },
  {
    label: "Avenir Next Heavy Italic",
    value: "AvenirNext-HeavyItalic",
  },
  {
    label: "Avenir Next Condensed Regular",
    value: "AvenirNextCondensed-Regular",
  },
  {
    label: "Avenir Next Condensed Italic",
    value: "AvenirNextCondensed-Italic",
  },
  {
    label: "Avenir Next Condensed UltraLight",
    value: "AvenirNextCondensed-UltraLight",
  },
  {
    label: "Avenir Next Condensed UltraLight Italic",
    value: "AvenirNextCondensed-UltraLightItalic",
  },
  {
    label: "Avenir Next Condensed Medium",
    value: "AvenirNextCondensed-Medium",
  },
  {
    label: "Avenir Next Condensed Medium Italic",
    value: "AvenirNextCondensed-MediumItalic",
  },
  {
    label: "Avenir Next Condensed DemiBold",
    value: "AvenirNextCondensed-DemiBold",
  },
  {
    label: "Avenir Next Condensed DemiBold Italic",
    value: "AvenirNextCondensed-DemiBoldItalic",
  },
  {
    label: "Avenir Next Condensed Bold",
    value: "AvenirNextCondensed-Bold",
  },
  {
    label: "Avenir Next Condensed Bold Italic",
    value: "AvenirNextCondensed-BoldItalic",
  },
  {
    label: "Avenir Next Condensed Heavy",
    value: "AvenirNextCondensed-Heavy",
  },
  {
    label: "Avenir Next Condensed Heavy Italic",
    value: "AvenirNextCondensed-HeavyItalic",
  },
  {
    label: "Baskerville",
    value: "Baskerville",
  },
  {
    label: "Baskerville Italic",
    value: "Baskerville-Italic",
  },
  {
    label: "Baskerville SemiBold",
    value: "Baskerville-SemiBold",
  },
  {
    label: "Baskerville SemiBold Italic",
    value: "Baskerville-SemiBoldItalic",
  },
  {
    label: "Baskerville Bold",
    value: "Baskerville-Bold",
  },
  {
    label: "Baskerville Bold Italic",
    value: "Baskerville-BoldItalic",
  },
  {
    label: "Bodoni 72 Book",
    value: "BodoniSvtyTwoITCTT-Book",
  },
  {
    label: "Bodoni 72 Book Italic",
    value: "BodoniSvtyTwoITCTT-BookIta",
  },
  {
    label: "Bodoni 72 Bold",
    value: "BodoniSvtyTwoITCTT-Bold",
  },
  {
    label: "Bodoni 72 Oldstyle Book",
    value: "BodoniSvtyTwoOSITCTT-Book",
  },
  {
    label: "Bodoni 72 Oldstyle Book Italic",
    value: "BodoniSvtyTwoOSITCTT-BookIt",
  },
  {
    label: "Bodoni 72 Oldstyle Bold",
    value: "BodoniSvtyTwoOSITCTT-Bold",
  },
  {
    label: "Bodoni 72 Smallcaps Book",
    value: "BodoniSvtyTwoSCITCTT-Book",
  },
  {
    label: "Bodoni Ornaments",
    value: "BodoniOrnamentsITCTT",
  },
  {
    label: "Bradley Hand Bold",
    value: "BradleyHandITCTT-Bold",
  },
  {
    label: "Chalkboard SE Regular",
    value: "ChalkboardSE-Regular",
  },
  {
    label: "Chalkboard SE Light",
    value: "ChalkboardSE-Light",
  },
  {
    label: "Chalkboard SE Bold",
    value: "ChalkboardSE-Bold",
  },
  {
    label: "Chalkduster",
    value: "Chalkduster",
  },
  {
    label: "Charter Roman",
    value: "Charter-Roman",
  },
  {
    label: "Charter Italic",
    value: "Charter-Italic",
  },
  {
    label: "Charter Bold",
    value: "Charter-Bold",
  },
  {
    label: "Charter Bold Italic",
    value: "Charter-BoldItalic",
  },
  {
    label: "Charter Black",
    value: "Charter-Black",
  },
  {
    label: "Charter Black Italic",
    value: "Charter-BlackItalic",
  },
  {
    label: "Cochin",
    value: "Cochin",
  },
  {
    label: "Cochin Italic",
    value: "Cochin-Italic",
  },
  {
    label: "Cochin Bold",
    value: "Cochin-Bold",
  },
  {
    label: "Cochin Bold Italic",
    value: "Cochin-BoldItalic",
  },
  {
    label: "Copperplate",
    value: "Copperplate",
  },
  {
    label: "Copperplate Light",
    value: "Copperplate-Light",
  },
  {
    label: "Copperplate Bold",
    value: "Copperplate-Bold",
  },
  {
    label: "Courier New",
    value: "CourierNewPSMT",
  },
  {
    label: "Courier New Italic",
    value: "CourierNewPS-ItalicMT",
  },
  {
    label: "Courier New Bold",
    value: "CourierNewPS-BoldMT",
  },
  {
    label: "Courier New Bold Italic",
    value: "CourierNewPS-BoldItalicMT",
  },
  {
    label: "Damascus",
    value: "Damascus",
  },
  {
    label: "Damascus Light",
    value: "DamascusLight",
  },
  {
    label: "Damascus Medium",
    value: "DamascusMedium",
  },
  {
    label: "Damascus SemiBold",
    value: "DamascusSemiBold",
  },
  {
    label: "Damascus Bold",
    value: "DamascusBold",
  },
  {
    label: "Devanagari Sangam MN",
    value: "DevanagariSangamMN",
  },
  {
    label: "Devanagari Sangam MN Bold",
    value: "DevanagariSangamMN-Bold",
  },
  {
    label: "Didot",
    value: "Didot",
  },
  {
    label: "Didot Italic",
    value: "Didot-Italic",
  },
  {
    label: "Didot Bold",
    value: "Didot-Bold",
  },
  {
    label: "DIN Alternate Bold",
    value: "DINAlternate-Bold",
  },
  {
    label: "DIN Condensed Bold",
    value: "DINCondensed-Bold",
  },
  {
    label: "Euphemia UCAS",
    value: "EuphemiaUCAS",
  },
  {
    label: "Euphemia UCAS Italic",
    value: "EuphemiaUCAS-Italic",
  },
  {
    label: "Euphemia UCAS Bold",
    value: "EuphemiaUCAS-Bold",
  },
  {
    label: "Farah",
    value: "Farah",
  },
  {
    label: "Futura Medium",
    value: "Futura-Medium",
  },
  {
    label: "Futura Medium Italic",
    value: "Futura-MediumItalic",
  },
  {
    label: "Futura Bold",
    value: "Futura-Bold",
  },
  {
    label: "Futura Condensed Medium",
    value: "Futura-CondensedMedium",
  },
  {
    label: "Futura Condensed ExtraBold",
    value: "Futura-CondensedExtraBold",
  },
  {
    label: "Galvji",
    value: "Galvji",
  },
  {
    label: "Galvji Bold",
    value: "Galvji-Bold",
  },
  {
    label: "Geeza Pro",
    value: "GeezaPro",
  },
  {
    label: "Geeza Pro Bold",
    value: "GeezaPro-Bold",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Georgia Italic",
    value: "Georgia-Italic",
  },
  {
    label: "Georgia Bold",
    value: "Georgia-Bold",
  },
  {
    label: "Georgia Bold Italic",
    value: "Georgia-BoldItalic",
  },
  {
    label: "Gill Sans",
    value: "GillSans",
  },
  {
    label: "Gill Sans Italic",
    value: "GillSans-Italic",
  },
  {
    label: "Gill Sans Light",
    value: "GillSans-Light",
  },
  {
    label: "Gill Sans Light Italic",
    value: "GillSans-LightItalic",
  },
  {
    label: "Gill Sans SemiBold",
    value: "GillSans-SemiBold",
  },
  {
    label: "Gill Sans SemiBold Italic",
    value: "GillSans-SemiBoldItalic",
  },
  {
    label: "Gill Sans Bold",
    value: "GillSans-Bold",
  },
  {
    label: "Gill Sans Bold Italic",
    value: "GillSans-BoldItalic",
  },
  {
    label: "Gill Sans UltraBold",
    value: "GillSans-UltraBold",
  },
  {
    label: "Grantha Sangam MN Regular",
    value: "GranthaSangamMN-Regular",
  },
  {
    label: "Grantha Sangam MN Bold",
    value: "GranthaSangamMN-Bold",
  },
  {
    label: "Helvetica",
    value: "Helvetica",
  },
  {
    label: "Helvetica Oblique",
    value: "Helvetica-Oblique",
  },
  {
    label: "Helvetica Light",
    value: "Helvetica-Light",
  },
  {
    label: "Helvetica Light Oblique",
    value: "Helvetica-LightOblique",
  },
  {
    label: "Helvetica Bold",
    value: "Helvetica-Bold",
  },
  {
    label: "Helvetica Bold Oblique",
    value: "Helvetica-BoldOblique",
  },
  {
    label: "Helvetica Neue",
    value: "HelveticaNeue",
  },
  {
    label: "Helvetica Neue Italic",
    value: "HelveticaNeue-Italic",
  },
  {
    label: "Helvetica Neue UltraLight",
    value: "HelveticaNeue-UltraLight",
  },
  {
    label: "Helvetica Neue UltraLight Italic",
    value: "HelveticaNeue-UltraLightItalic",
  },
  {
    label: "Helvetica Neue Thin",
    value: "HelveticaNeue-Thin",
  },
  {
    label: "Helvetica Neue Thin Italic",
    value: "HelveticaNeue-ThinItalic",
  },
  {
    label: "Helvetica Neue Light",
    value: "HelveticaNeue-Light",
  },
  {
    label: "Helvetica Neue Light Italic",
    value: "HelveticaNeue-LightItalic",
  },
  {
    label: "Helvetica Neue Medium",
    value: "HelveticaNeue-Medium",
  },
  {
    label: "Helvetica Neue Medium Italic",
    value: "HelveticaNeue-MediumItalic",
  },
  {
    label: "Helvetica Neue Bold",
    value: "HelveticaNeue-Bold",
  },
  {
    label: "Helvetica Neue Bold Italic",
    value: "HelveticaNeue-BoldItalic",
  },
  {
    label: "Helvetica Neue Condensed Bold",
    value: "HelveticaNeue-CondensedBold",
  },
  {
    label: "Helvetica Neue Condensed Black",
    value: "HelveticaNeue-CondensedBlack",
  },
  {
    label: "Hiragino Maru Gothic ProN",
    value: "HiraMaruProN-W4",
  },
  {
    label: "Hiragino Mincho ProN",
    value: "HiraMinProN-W3",
  },
  {
    label: "Hiragino Mincho ProN",
    value: "HiraMinProN-W6",
  },
  {
    label: "Hiragino Sans",
    value: "HiraginoSans-W3",
  },
  {
    label: "Hiragino Sans",
    value: "HiraginoSans-W6",
  },
  {
    label: "Hiragino Sans",
    value: "HiraginoSans-W7",
  },
  {
    label: "Hoefler Text",
    value: "HoeflerText-Regular",
  },
  {
    label: "Hoefler Text Italic",
    value: "HoeflerText-Italic",
  },
  {
    label: "Hoefler Text Black",
    value: "HoeflerText-Black",
  },
  {
    label: "Hoefler Text Black Italic",
    value: "HoeflerText-BlackItalic",
  },
  {
    label: "Impact",
    value: "Impact",
  },
  {
    label: "Kailasa",
    value: "Kailasa",
  },
  {
    label: "Kailasa Bold",
    value: "Kailasa-Bold",
  },
  {
    label: "Kefa",
    value: "Kefa-Regular",
  },
  {
    label: "Khmer Sangam MN",
    value: "KhmerSangamMN",
  },
  {
    label: "Kohinoor Bangla Regular",
    value: "KohinoorBangla-Regular",
  },
  {
    label: "Kohinoor Bangla Light",
    value: "KohinoorBangla-Light",
  },
  {
    label: "Kohinoor Bangla Bold",
    value: "KohinoorBangla-Bold",
  },
  {
    label: "Kohinoor Devanagari Regular",
    value: "KohinoorDevanagari-Regular",
  },
  {
    label: "Kohinoor Devanagari Light",
    value: "KohinoorDevanagari-Light",
  },
  {
    label: "Kohinoor Devanagari Bold",
    value: "KohinoorDevanagari-Bold",
  },
  {
    label: "Kohinoor Telugu Regular",
    value: "KohinoorTelugu-Regular",
  },
  {
    label: "Kohinoor Telugu Light",
    value: "KohinoorTelugu-Light",
  },
  {
    label: "Kohinoor Telugu Medium",
    value: "KohinoorTelugu-Medium",
  },
  {
    label: "Lao Sangam MN",
    value: "LaoSangamMN",
  },
  {
    label: "Malayalam Sangam MN",
    value: "MalayalamSangamMN",
  },
  {
    label: "Malayalam Sangam MN Bold",
    value: "MalayalamSangamMN-Bold",
  },
  {
    label: "Marker Felt Thin",
    value: "MarkerFelt-Thin",
  },
  {
    label: "Marker Felt Wide",
    value: "MarkerFelt-Wide",
  },
  {
    label: "Menlo",
    value: "Menlo",
  },
  {
    label: "Menlo Regular",
    value: "Menlo-Regular",
  },
  {
    label: "Menlo Italic",
    value: "Menlo-Italic",
  },
  {
    label: "Menlo Bold",
    value: "Menlo-Bold",
  },
  {
    label: "Menlo Bold Italic",
    value: "Menlo-BoldItalic",
  },
  {
    label: "Mishafi",
    value: "Mishafi",
  },
  {
    label: "Mishafi Gold",
    value: "MishafiGold",
  },
  {
    label: "Mishafi Gold Bold",
    value: "MishafiGold-Bold",
  },
  {
    label: "Mukta Mahee Regular",
    value: "MuktaMahee-Regular",
  },
  {
    label: "Mukta Mahee Light",
    value: "MuktaMahee-Light",
  },
  {
    label: "Mukta Mahee Bold",
    value: "MuktaMahee-Bold",
  },
  {
    label: "Myanmar Sangam MN",
    value: "MyanmarSangamMN",
  },
  {
    label: "Myanmar Sangam MN Bold",
    value: "MyanmarSangamMN-Bold",
  },
  {
    label: "Nadeem Regular",
    value: "Nadeem-Regular",
  },
  {
    label: "New Peninim MT",
    value: "NewPeninimMT",
  },
  {
    label: "New Peninim MT Bold",
    value: "NewPeninimMT-Bold",
  },
  {
    label: "Noteworthy",
    value: "Noteworthy-Light",
  },
  {
    label: "Noteworthy-Bold",
    value: "Noteworthy-Bold",
  },
  {
    label: "Noto Nastaliq Urdu",
    value: "NotoNastaliqUrdu",
  },
  {
    label: "Noto Nastaliq Urdu Bold",
    value: "NotoNastaliqUrdu-Bold",
  },
  {
    label: "Noto Sans Kannada Regular",
    value: "NotoSansKannada-Regular",
  },
  {
    label: "Noto Sans Kannada Light",
    value: "NotoSansKannada-Light",
  },
  {
    label: "Noto Sans Kannada Bold",
    value: "NotoSansKannada-Bold",
  },
  {
    label: "Oriya Sangam MN",
    value: "OriyaSangamMN",
  },
  {
    label: "Oriya Sangam MN Bold",
    value: "OriyaSangamMN-Bold",
  },
  {
    label: "Palatino Roman",
    value: "Palatino-Roman",
  },
  {
    label: "Palatino Italic",
    value: "Palatino-Italic",
  },
  {
    label: "Palatino Bold",
    value: "Palatino-Bold",
  },
  {
    label: "Palatino Bold Italic",
    value: "Palatino-BoldItalic",
  },
  {
    label: "Papyrus",
    value: "Papyrus",
  },
  {
    label: "Papyrus Condensed",
    value: "Papyrus-Condensed",
  },
  {
    label: "Party LET",
    value: "PartyLetPlain",
  },
  {
    label: "Savoye LET Plain",
    value: "SavoyeLetPlain",
  },
  {
    label: "San Francisco",
    value: "San Francisco",
  },
  {
    label: "Sinhala Sangam MN",
    value: "SinhalaSangamMN",
  },
  {
    label: "Sinhala Sangam MN Bold",
    value: "SinhalaSangamMN-Bold",
  },
  {
    label: "Snell Roundhand",
    value: "SnellRoundhand",
  },
  {
    label: "Snell Roundhand Bold",
    value: "SnellRoundhand-Bold",
  },
  {
    label: "Snell Roundhand Black",
    value: "SnellRoundhand-Black",
  },
  {
    label: "Symbol",
    value: "Symbol",
  },
  {
    label: "Tahoma",
    value: "Tahoma",
  },
  {
    label: "Tahoma Bold",
    value: "Tahoma-Bold",
  },
  {
    label: "Tamil Sangam MN",
    value: "TamilSangamMN",
  },
  {
    label: "Tamil Sangam MN Bold",
    value: "TamilSangamMN-Bold",
  },
  {
    label: "Telugu Sangam MN",
    value: "TeluguSangamMN",
  },
  {
    label: "Telugu Sangam MN Bold",
    value: "TeluguSangamMN-Bold",
  },
  {
    label: "Thonburi",
    value: "Thonburi",
  },
  {
    label: "Thonburi Bold",
    value: "Thonburi-Bold",
  },
  {
    label: "Thonburi Light",
    value: "Thonburi-Light",
  },
  {
    label: "Thonburi Light Italic",
    value: "Thonburi-LightItalic",
  },
  {
    label: "Thonburi Bold Italic",
    value: "Thonburi-BoldItalic",
  },
  {
    label: "Times New Roman",
    value: "TimesNewRomanPSMT",
  },
  {
    label: "Times New Roman Italic",
    value: "TimesNewRomanPS-ItalicMT",
  },
  {
    label: "Times New Roman Bold",
    value: "TimesNewRomanPS-BoldMT",
  },
  {
    label: "Times New Roman Bold Italic",
    value: "TimesNewRomanPS-BoldItalicMT",
  },
  {
    label: "Trebuchet MS",
    value: "TrebuchetMS",
  },
  {
    label: "Trebuchet MS Italic",
    value: "TrebuchetMS-Italic",
  },
  {
    label: "Trebuchet MS Bold",
    value: "TrebuchetMS-Bold",
  },
  {
    label: "Trebuchet MS Bold Italic",
    value: "TrebuchetMS-BoldItalic",
  },
  {
    label: "Verdana",
    value: "Verdana",
  },
  {
    label: "Verdana Italic",
    value: "Verdana-Italic",
  },
  {
    label: "Verdana Bold",
    value: "Verdana-Bold",
  },
  {
    label: "Verdana Bold Italic",
    value: "Verdana-BoldItalic",
  },
  {
    label: "Zapf Dingbats",
    value: "ZapfDingbatsITC",
  },
  {
    label: "Zapfino",
    value: "Zapfino",
  },
  {
    label: "Zapfino Extra LT",
    value: "Zapfino-ExtraLT",
  },
]

export const OLD_IOS_DEFAULT_FONTS = [
  {
    label: "Serif",
    value: "serif",
  },
  {
    label: "Sans Serif",
    value: "sans-serif",
  },
  {
    label: "Monospace",
    value: "monospace",
  },
  {
    label: "Helvetica",
    value: "Helvetica",
  },
  {
    label: "San Francisco",
    value: "San Francisco",
  },
]

export const customStyles = {
  control: (base, state) => ({
    ...base,
    maxHeight: 34,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 200,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}
