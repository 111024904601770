export const WALKTHROUGH_LIGHT_THEME = {
  _id: "17456ca9-8615-4682-8ef2-1f93bs5445s7s",
  name: "Light Theme",
  config_type: "walkthrough",
  created_at: "December 13, 2023 2:27 PM",
  updated_at: "December 13, 2023 2:30 PM",
  created_by: "admin@apxor.com",
  ui: {
    state: "apx_default_theme",
    containerConfigs: {
      container_color: "#ffffff",
      corner_radius: 5,
      border_stroke: {
        stroke_color: "#0a0a0a",
        stroke_width: 0,
        stroke_style: "solid",
      },
      close_icon: {
        close_icon_color: "#000000",
        height: 20,
        width: 20,
        offset_x: 10,
        offset_y: 10,
        close_icon_type: "filled",
        close_icon_fill_color: "#C7C3C3",
        close_icon_fill_shape: "circle",
      },
      inside_container: {
        container_color: "#ffffff",
        corner_radius: 0,
      },
    },
    textConfigs: {
      title: {
        title_font_size: 18,
        title_font_color: "#000000",
        title_font_style: "normal",
        title_font_weight: "bold",
        title_font_family: "sans-serif",
        title_text: "Title",
        title_alignment: "left",
      },
      description: {
        desc_font_size: 15,
        desc_font_color: "#000000",
        desc_font_style: "normal",
        desc_font_family: "sans-serif",
        desc_font_weight: "normal",
        desc_text: "Description",
        desc_alignment: "left",
      },
    },
    buttonConfigs: [
      {
        id: "btn-1",
        label: "Primary",
        button_type: "none",
        filled_button_color: "#27A4F2",
        border_color: "#000000",
        border_width: 0,
        corner_radius: 2,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          font_color: "#000000",
          font_style: "normal",
          font_family: "sans-serif",
          font_weight: "normal",
          text: "Text",
        },
      },
      {
        id: "btn-2",
        label: "Secondary",
        button_type: "filled",
        filled_button_color: "#f28a27",
        border_color: "#000000",
        border_width: 0,
        corner_radius: 6,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          font_color: "#ffffff",
          font_style: "normal",
          font_family: "sans-serif",
          font_weight: "normal",
          text: "Text",
        },
      },
    ],
    edit: true,
    draft: false,
  },
  meta: {
    createdAt: {
      $date: 1694781646779,
    },
    updatedAt: {
      $date: 1694781646779,
    },
  },
}

export const WALKTHROUGH_DARK_THEME = {
  _id: "17456ca9-8615-4682-8ef2-1sk66s5s8s441212",
  name: "Dark Theme",
  config_type: "walkthrough",
  created_at: "December 13, 2023 2:27 PM",
  updated_at: "December 13, 2023 2:30 PM",
  created_by: "admin@apxor.com",
  ui: {
    state: "apx_default_theme",
    containerConfigs: {
      container_color: "#020202",
      corner_radius: 5,
      border_stroke: {
        stroke_color: "#0a0a0a",
        stroke_width: 0,
        stroke_style: "solid",
      },
      close_icon: {
        close_icon_color: "#000000",
        height: 20,
        width: 20,
        offset_x: 10,
        offset_y: 10,
        close_icon_type: "filled",
        close_icon_fill_color: "#C7C3C3",
        close_icon_fill_shape: "circle",
      },
      inside_container: {
        container_color: "#ffffff",
        corner_radius: 0,
      },
    },
    textConfigs: {
      title: {
        title_font_size: 18,
        title_font_color: "#ffffff",
        title_font_style: "normal",
        title_font_weight: "bold",
        title_font_family: "sans-serif",
        title_text: "Title",
        title_alignment: "left",
      },
      description: {
        desc_font_size: 15,
        desc_font_color: "#ffffff",
        desc_font_style: "normal",
        desc_font_family: "sans-serif",
        desc_font_weight: "normal",
        desc_text: "Description",
        desc_alignment: "left",
      },
    },
    buttonConfigs: [
      {
        id: "btn-1",
        label: "Primary",
        button_type: "none",
        filled_button_color: "#27A4F2",
        border_color: "#000000",
        border_width: 0,
        corner_radius: 2,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          font_color: "#ffffff",
          font_style: "normal",
          font_family: "sans-serif",
          font_weight: "normal",
          text: "Text",
        },
      },
      {
        id: "btn-2",
        label: "Secondary",
        button_type: "filled",
        filled_button_color: "#ffffff",
        border_color: "#000000",
        border_width: 0,
        corner_radius: 6,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          font_color: "#000000",
          font_style: "normal",
          font_family: "sans-serif",
          font_weight: "normal",
          text: "Text",
        },
      },
    ],
    edit: true,
    draft: false,
  },
  meta: {
    createdAt: {
      $date: 1694781646779,
    },
    updatedAt: {
      $date: 1694781646779,
    },
  },
}

export const SURVEY_LIGHT_THEME = {
  _id: "17456ca9-8615-4682-8ef2-1sk66s5s8s449",
  name: "Light Theme",
  config_type: "survey",
  created_at: "December 13, 2023 2:27 PM",
  updated_at: "December 13, 2023 2:30 PM",
  created_by: "admin@apxor.com",
  ui: {
    state: "apx_default_theme",
    edit: true,
    draft: false,

    containerConfigs: {
      container_color: "#ffffff",
      corner_radius: 5,
      border_stroke: {
        stroke_color: "#000000",
        stroke_width: 1,
        stroke_style: "solid",
      },
      close_icon: {
        close_icon_color: "#000000",
        height: 20,
        width: 20,
        close_icon_type: "filled",
        close_icon_fill_color: "#C7C3C3",
        close_icon_fill_shape: "circle",
      },
      progress_bar: {
        enable_progress_bar: true,
        complation_color: "#1a90ff",
        incomplation_color: "#C7C3C3",
        bar_height: 5,
      },
      previous_button: {
        enable_previous_btn: true,
        previous_button_color: "#000000",
        previous_button_height: 20,
        previous_button_width: 20,
        previous_button_type: "filled",
        previous_button_fill_color: "#C7C3C3",
        previous_button_fill_shape: "circle",
      },
      stepper: {
        enable_stepper: true,
        stepper_type: "slash",
        text_configs: {
          stepper_font_size: 14,
          stepper_font_color: "#000000",
          stepper_font_style: "normal",
          stepper_font_family: "roboto",
        },
      },
    },
    colorArray: [
      {
        name: "Transparent",
        code: "transparent",
      },
      {
        name: "Red",
        code: "#FF0000",
      },
      {
        name: "Green",
        code: "#008000",
      },
      {
        name: "Blue",
        code: "#0000FF",
      },
      {
        name: "Yellow",
        code: "#FFFF00",
      },
      {
        name: "Orange",
        code: "#FFA500",
      },
      {
        name: "Purple",
        code: "#800080",
      },
      {
        name: "Pink",
        code: "#FFC0CB",
      },
      {
        name: "Cyan",
        code: "#00FFFF",
      },
      {
        name: "Magenta",
        code: "#FF00FF",
      },
      {
        name: "Brown",
        code: "#A52A2A",
      },
      {
        name: "White",
        code: "#FFFFFF",
      },
      {
        name: "Black",
        code: "#000000",
      },
      {
        name: "Gray",
        code: "#808080",
      },
      {
        name: "Light Gray",
        code: "#D3D3D3",
      },
      {
        name: "Dark Gray",
        code: "#A9A9A9",
      },
      {
        name: "None",
        code: "none",
      },
    ],
    surveyBodyConfigs: {
      choice_config: {
        choice_layout_type: "filled",
        active_bg_color: "#fd8d00",
        radio_color_active: "#FFFFFF",
        radio_color_inactive: "#000000",
        inactive_bg_color: "#C7C3C3",
        inactive_border_color: "#C7C3C3",
        active_border_color: "#1a90ff",
        active_text_color: "#000000",
        inactive_text_color: "#000000",
      },
      background_color: "#ffffff",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
      feedback_box_config: {
        text_box_type: "filled",
        text_box_input_color: "#ffffff",
        text_box_color: "#000000",
        text_box_border_color: "#000000",
      },
      text_configs: {
        question: {
          question_font_size: 14,
          question_font_color: "#000000",
          question_font_style: "normal",
          question_font_family: "roboto",
          question_font_weight: "bold",
          question_font_alignment: "center",
        },
        description: {
          enable_description: true,
          survey_body_font_size: 12,
          survey_body_font_color: "#000000",
          survey_body_font_style: "normal",
          survey_body_font_family: "serif",
          survey_body_font_weight: "normal",
          survey_body_font_alignment: "center",
        },
      },
    },
    surveyHeaderConfigs: {
      background_color: "#FFFFFF",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
      title: {
        header_title_font_size: 14,
        header_title_font_color: "#000000",
        header_title_font_style: "normal",
        header_title_font_family: "roboto",
        header_title_font_weight: "bold",
        header_title_font_alignment: "center",
      },
      description: {
        header_desc_font_size: 12,
        header_desc_font_color: "#000000",
        header_desc_font_style: "normal",
        header_desc_font_family: "serif",
        header_desc_font_weight: "normal",
        header_desc_font_alignment: "center",
      },
    },
    buttonConfigs: [
      {
        id: "btn-1",
        label: "Primary",
        button_type: "normal",
        active_filled_button_color: "#fd8d00",
        inactive_filled_button_color: "#C7C3C3",
        border_color: "#000000",
        border_width: 0,
        corner_radius: 2,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          active_font_color: "#000000",
          inactive_font_color: "#000000",
          font_style: "normal",
          font_family: "serif",
          font_weight: "normal",
          text: "Next",
        },
        active_filled_border_color: "#FFFFFF",
      },
    ],
    footer_configs: {
      background_color: "#FFFFFF",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
    },
  },
  meta: {
    createdAt: {
      $date: 1694781646779,
    },
    updatedAt: {
      $date: 1694781646779,
    },
  },
}

export const SURVEY_DARK_THEME = {
  _id: "17456ca9-8615-4682-8ef2-1sk66s5s8s44s",
  name: "Dark Theme",
  config_type: "survey",
  created_at: "December 13, 2023 2:27 PM",
  updated_at: "December 13, 2023 2:30 PM",
  created_by: "admin@apxor.com",
  ui: {
    state: "apx_default_theme",
    edit: true,
    draft: false,
    containerConfigs: {
      container_color: "#ffffff",
      corner_radius: 5,
      border_stroke: {
        stroke_color: "#000000",
        stroke_width: 1,
        stroke_style: "solid",
      },
      close_icon: {
        close_icon_color: "#000000",
        height: 20,
        width: 20,
        close_icon_type: "filled",
        close_icon_fill_color: "#C7C3C3",
        close_icon_fill_shape: "circle",
      },
      progress_bar: {
        enable_progress_bar: true,
        complation_color: "#1a90ff",
        incomplation_color: "#C7C3C3",
        bar_height: 5,
      },
      previous_button: {
        enable_previous_btn: true,
        previous_button_color: "#000000",
        previous_button_height: 20,
        previous_button_width: 20,
        previous_button_type: "filled",
        previous_button_fill_color: "#C7C3C3",
        previous_button_fill_shape: "circle",
      },
      stepper: {
        enable_stepper: true,
        stepper_type: "slash",
        text_configs: {
          stepper_font_size: 14,
          stepper_font_color: "#ffffff",
          stepper_font_style: "normal",
          stepper_font_family: "roboto",
        },
      },
    },
    colorArray: [
      {
        name: "Transparent",
        code: "transparent",
      },
      {
        name: "Red",
        code: "#FF0000",
      },
      {
        name: "Green",
        code: "#008000",
      },
      {
        name: "Blue",
        code: "#0000FF",
      },
      {
        name: "Yellow",
        code: "#FFFF00",
      },
      {
        name: "Orange",
        code: "#FFA500",
      },
      {
        name: "Purple",
        code: "#800080",
      },
      {
        name: "Pink",
        code: "#FFC0CB",
      },
      {
        name: "Cyan",
        code: "#00FFFF",
      },
      {
        name: "Magenta",
        code: "#FF00FF",
      },
      {
        name: "Brown",
        code: "#A52A2A",
      },
      {
        name: "White",
        code: "#FFFFFF",
      },
      {
        name: "Black",
        code: "#000000",
      },
      {
        name: "Gray",
        code: "#808080",
      },
      {
        name: "Light Gray",
        code: "#D3D3D3",
      },
      {
        name: "Dark Gray",
        code: "#A9A9A9",
      },
      {
        name: "None",
        code: "none",
      },
    ],
    surveyBodyConfigs: {
      choice_config: {
        choice_layout_type: "filled",
        active_bg_color: "#000000",
        radio_color_active: "#FFFFFF",
        radio_color_inactive: "#000000",
        inactive_bg_color: "#C7C3C3",
        inactive_border_color: "#C7C3C3",
        active_border_color: "#1a90ff",
        active_text_color: "#ffffff",
        inactive_text_color: "#000000",
      },
      background_color: "#FFFFFF",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
      feedback_box_config: {
        text_box_type: "filled",
        text_box_input_color: "#ffffff",
        text_box_color: "#000000",
        text_box_border_color: "#000000",
      },
      text_configs: {
        question: {
          question_font_size: 14,
          question_font_color: "#000000",
          question_font_style: "normal",
          question_font_family: "roboto",
          question_font_weight: "bold",
          question_font_alignment: "center",
        },
        description: {
          enable_description: true,
          survey_body_font_size: 12,
          survey_body_font_color: "#000000",
          survey_body_font_style: "normal",
          survey_body_font_family: "serif",
          survey_body_font_weight: "normal",
          survey_body_font_alignment: "center",
        },
      },
    },
    surveyHeaderConfigs: {
      background_color: "#000000",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
      title: {
        header_title_font_size: 14,
        header_title_font_color: "#ffffff",
        header_title_font_style: "normal",
        header_title_font_family: "roboto",
        header_title_font_weight: "bold",
        header_title_font_alignment: "center",
      },
      description: {
        header_desc_font_size: 12,
        header_desc_font_color: "#ffffff",
        header_desc_font_style: "normal",
        header_desc_font_family: "serif",
        header_desc_font_weight: "normal",
        header_desc_font_alignment: "center",
      },
    },
    buttonConfigs: [
      {
        id: "btn-1",
        label: "Primary",
        button_type: "normal",
        active_filled_button_color: "#000000",
        inactive_filled_button_color: "#C7C3C3",
        border_color: "#000000",
        border_width: 1,
        corner_radius: 2,
        border_style: "solid",
        enable_border: true,
        button_text: {
          font_size: 15,
          active_font_color: "#ffffff",
          inactive_font_color: "#000000",
          font_style: "normal",
          font_family: "serif",
          font_weight: "normal",
          text: "Next",
        },
        active_filled_border_color: "#000000",
      },
    ],
    footer_configs: {
      background_color: "#FFFFFF",
      border: {
        stroke_radius: 0,
        stroke_color: "#000000",
        stroke_width: 0,
        stroke_style: "solid",
      },
    },
  },
  meta: {
    createdAt: {
      $date: 1694781646779,
    },
    updatedAt: {
      $date: 1694781646779,
    },
  },
}

export const WALKTHROUGH_INITIAL_DEFAULT_THEME = [
  WALKTHROUGH_LIGHT_THEME,
  WALKTHROUGH_DARK_THEME,
]

export const SURVEY_INITIAL_DEFAULT_THEME = [
  SURVEY_LIGHT_THEME,
  SURVEY_DARK_THEME,
]

export const THEMES_DIALOGS_CONSTANTS = Object.freeze({
  CLOSED: "CLOSED",
  ADD: "ADD",
  EDIT: "EDIT",
  DELETE: "DELETE",
})

// Font File Formats
export const CUSTOM_FONT_FILE_FORMATS = Object.freeze({
  TTF: "ttf",
  OTF: "otf",
  WOFF: "woff",
  WOFF2: "woff2",
})

export const getCustomFontFileFormat = (format) => {
  switch (format) {
    case CUSTOM_FONT_FILE_FORMATS.TTF:
      return "TTF"
    case CUSTOM_FONT_FILE_FORMATS.OTF:
      return "OTF"
    case CUSTOM_FONT_FILE_FORMATS.WOFF:
      return "WOFF"
    case CUSTOM_FONT_FILE_FORMATS.WOFF2:
      return "WOFF2"
    default:
      return "TTF"
  }
}

// Android and Omni - Font Source
export const CUSTOM_FONT_SOURCES = Object.freeze({
  ASSETS: "assets",
  RESOURCES: "res",
  PATH: "path",
  URL: "url",
})

export const getCustomFontSource = (source) => {
  switch (source) {
    case CUSTOM_FONT_SOURCES.ASSETS:
      return "Assets"
    case CUSTOM_FONT_SOURCES.RESOURCES:
      return "Resources"
    case CUSTOM_FONT_SOURCES.PATH:
      return "Path"
    case CUSTOM_FONT_SOURCES.URL:
      return "URL"
    default:
      return "Assets"
  }
}

// Web and Omni - Font Type
export const CUSTOM_FONT_TYPES = Object.freeze({
  NAME: "name",
  PATH: "path",
  URL: "url",
})

export const getCustomFontType = (type) => {
  switch (type) {
    case CUSTOM_FONT_TYPES.NAME:
      return "Name"
    case CUSTOM_FONT_TYPES.PATH:
      return "Path"
    case CUSTOM_FONT_TYPES.URL:
      return "URL"
    default:
      return ""
  }
}
