export const FEATURES_LIST_ENUM = Object.freeze({
  NUDGES: {
    id: "Nudges",
    children: {
      DASHBOARD: { label: "Dashboard", value: "APX_DASHBOARD" },
      AB: { label: "A/B Testing", value: "APX_AB" },
      CAMPAIGNS: { label: "Campaigns", value: "APX_WALKTHROUGHS" },
      SURVEYS: { label: "Surveys", value: "APX_SURVEYS" },
      PUSH: { label: "Push", value: "APX_PUSH" },
      NEW_SURVEYS: {
        label: "New Surveys",
        value: "APX_NEW_SURVEYS",
      },
      BADGES: {
        label: "Badges",
        value: "APX_BADGES",
      },
      COACHMARKS_V2: {
        label: "Coachmarks V2",
        value: "APX_COACHMARKS_V2",
      },
      NUDGE_VERSION_HISTORY: {
        label: "Nudge Version History",
        value: "APX_NUDGE_VERSION_HISTORY",
      },
      WEB_VIEW_TEMPLATES: {
        label: "Web View Templates",
        value: "APX_WEB_VIEW_TEMPLATES",
      },
      SCREEN_EXPLAINER: {
        label: "Screen Explainer",
        value: "APX_SCREEN_EXPLAINER",
      },
      AVT: {
        label: "Auto View Tagging",
        value: "APX_AVT",
      },
      AUTO_TRACK_EVENTS: {
        label: "Auto Track Events",
        value: "APX_AUTO_TRACK_EVENTS",
      },
      AUTO_TRACK_PAGES: {
        label: "Auto Track Pages",
        value: "APX_AUTO_TRACK_PAGES",
      },
      FUNNEL_DROPOFF: {
        label: "Funnel Dropoff",
        value: "APX_FUNNEL_DROPOFF",
      },
      FUNNEL_DROPOFF_ONLY: {
        label: "Funnel Dropoff V2",
        value: "APX_FUNNEL_DROPOFF_ONLY",
      },
      LEGACY_CONDITIONS: {
        label: "Legacy Conditions",
        value: "APX_LEGACY_CONDITIONS",
      },
      WORKFLOW_APPROVAL: {
        label: "Workflow Approval",
        value: "APX_WORKFLOW_APPROVAL",
      },
      CAPTURE_SCREEN: {
        label: "Capture Screen",
        value: "APX_CAPTURE_SCREEN",
      },
    },
  },
  ANALYTICS: {
    id: "Analytics",
    children: {
      CUSTOM_DASHBOARDS: {
        label: "Custom Dashboards",
        value: "APX_CUSTOM_DASHBOARDS",
      },
      TEMPLATES_RETENTION: { label: "Retention", value: "APX_RETENTION" },
      UNINSTALLS: { label: "Uninstalls", value: "APX_UNINSTALLS" },
      FUNNELS: { label: "Funnels", value: "APX_FUNNELS" },
      PATHS: { label: "Paths", value: "APX_PATH" },
    },
  },
  INSIGHTS: {
    id: "Insights",
    children: {
      ACTIVATION_ANALYSIS: {
        label: "Activation Analysis",
        value: "APX_ACTIVATION_ANALYSIS",
      },
      CORRELATION_ANALYSIS: {
        label: "Correlation Analysis",
        value: "APX_CORRELATION_ANALYSIS",
      },
    },
  },
  REPORTING: {
    id: "Reporting",
    children: {
      CUSTOM_REPORTS: { label: "Custom Reports", value: "APX_CUSTOM_REPORTS" },
    },
  },
  EXPLORER: {
    id: "Explorer",
    children: {
      EVENT_EXPLORER: { label: "Event Explorer", value: "APX_EVENT_ANALYSIS" },
      USER_EXPLORER: { label: "User Explorer", value: "APX_USER_EXPLORER" },
    },
  },
  TARGET_AUDIENCE: {
    id: "Target Audience",
    children: {
      COHORTS: { label: "Cohorts", value: "APX_COHORTS" },
      SEGMENTS: { label: "Segments", value: "APX_SEGMENTS" },
      DYNAMIC_COHORTS: {
        label: "Dynamic Cohorts",
        value: "APX_DYNAMIC_COHORTS",
      },
    },
  },
  SETTINGS: {
    id: "Settings",
    children: {
      MANAGEPLAN: { label: "Manage Plan", value: "APX_SETTINGS_MANAGE_PLAN" },
      IAM: { label: "IAM", value: "APX_SETTINGS_IAM" },
      SDK: { label: "SDK", value: "APX_SETTINGS_SDK" },
      TEST_DEVICES: {
        label: "Test Devices",
        value: "APX_SETTINGS_TEST_DEVICES",
      },
      DOWNLOADS: { label: "Downloads", value: "APX_SETTINGS_DATA" },
      EVENTS_MANAGEMENT: {
        label: "Events Management",
        value: "APX_SETTINGS_EVENTS_MANAGEMENT",
      },
      METRICS: {
        label: "Metrics",
        value: "APX_SETTINGS_METRICS",
      },
    },
  },
  THEMES: {
    id: "Themes",
    children: {
      GLOBAL_THEMES: { label: "Global Themes", value: "APX_GLOBAL_THEMES" },
      CUSTOM_FONTS: { label: "Custom Fonts", value: "APX_CUSTOM_FONTS" },
    },
  },
})

export const FEATURES_LIST = Object.freeze({
  Nudges: [
    "APX_DASHBOARD",
    "APX_WALKTHROUGHS",
    "APX_AB",
    "APX_SURVEYS",
    "APX_PUSH",
    "APX_NEW_SURVEYS",
    "APX_BADGES",
    "APX_COACHMARKS_V2",
    "APX_NUDGE_VERSION_HISTORY",
    "APX_WEB_VIEW_TEMPLATES",
    "APX_SCREEN_EXPLAINER",
    "APX_WORKFLOW_APPROVAL",
    "APX_AVT",
    "APX_AUTO_TRACK_EVENTS",
    "APX_AUTO_TRACK_PAGES",
    "APX_FUNNEL_DROPOFF",
    "APX_FUNNEL_DROPOFF_ONLY",
    "APX_LEGACY_CONDITIONS",
    "APX_CAPTURE_SCREEN",
  ],
  Analytics: [
    "APX_CUSTOM_DASHBOARDS",
    "APX_RETENTION",
    "APX_UNINSTALLS",
    "APX_FUNNELS",
    "APX_PATH",
  ],
  Insights: ["APX_ACTIVATION_ANALYSIS", "APX_CORRELATION_ANALYSIS"],
  Reporting: ["APX_CUSTOM_REPORTS"],
  Explorer: ["APX_EVENT_ANALYSIS", "APX_USER_EXPLORER"],
  "Target Audience": ["APX_COHORTS", "APX_SEGMENTS", "APX_DYNAMIC_COHORTS"],
  Settings: [
    "APX_SETTINGS_MANAGE_PLAN",
    "APX_SETTINGS_IAM",
    "APX_SETTINGS_SDK",
    "APX_SETTINGS_TEST_DEVICES",
    "APX_SETTINGS_DATA",
    "APX_SETTINGS_EVENTS_MANAGEMENT",
    "APX_SETTINGS_METRICS",
  ],
  Themes: ["APX_GLOBAL_THEMES", "APX_CUSTOM_FONTS"],
})
