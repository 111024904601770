export const API_QUERY_PARAMS = {
  customerId: "customerId",
  appId: "appId",
}

export const AUTH_ERROR_ENUM = {
  //re login require messages
  UNAUTHORISED: "Please login to continue..!",
  MISSING_TOKEN: "Session expired. Please login to continue..!",
  MISSING_HOST: "Something went wrong. Please login again..!",
}

export const APXOR_SDK_CATEGORY_CONSTANTS = {
  RETENTION: "Retention",
  FUNNELS: "Funnels",
  BEHAVIOUR_CORRELATION_FRAMEWORK: "Behaviour Correlation Framework",
  COHORT: "Cohort",
  VERSION: "Version",
  EVENT: "Event Analysis",
  DASHBOARD: "Dashboard",
  NAVIGATION: "Navigation",
  UNINSTALLS: "Uninstalls",
  USER_SEGMENTS: "User Segments",
  USER_EXPLORER: "User Explorer",
  CAMPAIGNS: "Campaigns",
}

export const DASHBOARD_ENDPOINTS = {
  ACTIVATION_ANALYSIS: "activation-analysis",
  ALERTS: "alerts",
  APPS: "apps",
  APPROVAL: "approve",
  AUDIENCE: "audience",
  CONTEXTS: "contexts",
  COHORTS: "cohorts",
  CORRELATION_ANALYSIS: "correlation-analysis",
  CREATE: "create",
  CUSTOM_GROUPING: "custom-grouping",
  CUSTOM_REPORTS: "custom-reports",
  CUSTOM_FONTS: "custom-fonts",
  DYNAMIC_COHORTS: "dynamic-cohorts",
  CUSTOM_DASHBOARDS: "custom-dashboards",
  DASHBOARD: "dashboard",
  DASHBOARDS: "dashboards",
  DOWNLOADS: "downloads",
  DROP_OFF: "drop-off",
  ENGAGEMENT_REPORT: "reports/key-metrics",
  EVENT_ANALYSIS: "event-analysis",
  FUNNELS: "funnels",
  GLOBAL_THEMES: "themes",
  HEALTH: "health",
  INCIDENTS: "incidents",
  INSIGHTS: "insights",
  INTEGRATION: "integration",
  ISSUE_DETAILS: "issues",
  LOGIN: "login",
  MESSAGES: "messages",
  WALKTHROUGHS: "walkthroughs",
  OVERVIEW: "overview",
  PATH: "path",
  PREPROCESS: "preprocess",
  PROFILE: "profile",
  PUSH: "push",
  AUTO_TRACK_EVENTS: "auto-tracked-events",
  AUTO_TRACK_PAGES: "auto-tracked-pages",
  REPORTS: "reports",
  RESET_PASSWORD: "reset-password",
  RETENTION: "retention",
  SEGMENTS: "segments",
  SETTINGS: "settings",
  SETTINGS_DATA: "settings/downloads",
  SETTINGS_GENERAL: "settings/general",
  SETTINGS_IAM: "settings/iam",
  SETTINGS_MANAGE_PLAN: "settings/manage-plan",
  SETTINGS_EM: "settings/events",
  SETTINGS_SDK: "settings/sdk",
  SETTINGS_SESSIONS: "settings/sessions",
  SETTINGS_TEST_DEVICES: "settings/test-devices",
  SETTINGS_USERS: "settings/users",
  SETTINGS_METRICS: "settings/metrics",
  SIGN_UP: "sign_up",
  SURVEYS: "surveys",
  UNAUTHORISED: "unauthorised",
  UNINSTALL_REPORT: "reports/day0-analysis",
  UNINSTALLS: "uninstalls",
  USER: "user",
  USER_EXPLORER: "user-explorer",
  USERS: "users",
  REQUESTS: "requests",
}

export const NON_DASHBOARD_ENDPOINTS = [
  DASHBOARD_ENDPOINTS.LOGIN,
  DASHBOARD_ENDPOINTS.SIGN_UP,
  DASHBOARD_ENDPOINTS.INTEGRATION,
  DASHBOARD_ENDPOINTS.APPROVAL,
  DASHBOARD_ENDPOINTS.CREATE,
]
